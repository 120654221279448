import React, { useState } from 'react';
import {Button, Dropdown, Form, Row, Table} from "react-bootstrap";
import {ApiOperations} from "../api/apiOperations";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css'

class ConsultationRecord extends React.Component {

    state = {
        selectedClient: ["Selecione o Cliente", null], // Nome e Código do cliente
        selectedExploration: "Selecione a Exploração",
        clients: [],
        explorations: [],
        products: [],
        warehouse: "",
        blocking: false,
        productNameFilter: ""
    }

    constructor(props) {
        super(props);
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    /**
     * Obtenção dos clientes e dos produtos presentes no armazém do veterinário
     */
    async fetchData() {
        // Bloqueio da interface enquanto se carrega os dados
        this.toggleBlocking()

        const api = new ApiOperations();
        const warehouse = this.props.warehouse;

        // Obtenção dos clientes e dos produtos
        let clients = await api.getClients();
        let products = await api.getProductsInWarehouse(warehouse);

        // Atualização do state com os clientes e os produtos
        this.setState({ clients: clients, products: products.artigos, warehouse: warehouse });

        // Desbloqueio da interface depois dos dados carregados
        this.toggleBlocking()
    }

    /**
     * Método responsável por inverter a variável que determina o bloqueio da interface
     */
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    render() {
        let clients = this.state.clients;
        let selectedExploration = this.state.selectedExploration;
        let selectedClient = this.state.selectedClient[0];
        let explorations = this.state.explorations;
        let products = this.state.products;

        // Configuração do filtro/pesquisa no cliente
        const CustomMenu = React.forwardRef(
            ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
                const [value, setValue] = useState('');

                return (
                    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                        <Form.Control autoFocus className="mx-3 my-2 w-75" placeholder="Filtrar por Nome ou N.º de Contribuinte..."
                            onChange={(e) => setValue(e.target.value)} value={value}/>
                        <ul className="list-unstyled">
                            {React.Children.toArray(children).filter(
                                (child) => {
                                    /* Critérios do filtro de pesquisa:
                                         - Pelo nome do cliente
                                         - Pelo NIF do cliente
                                     */
                                    let text = value.toLowerCase().trim()
                                    let clientName = child.props.children;
                                    let clientObj = clients.find(c => c.nome === clientName);
                                    return !text ||
                                        clientName.toLowerCase().includes(text) ||
                                        clientObj.nif.includes(text)
                                }
                            )} </ul>
                    </div>
                );
            },
        );

        return <BlockUi tag="div" blocking={this.state.blocking}>
            <hr />

            <Row className="justify-content-center">
                <h3 className={"col-auto"}>Registar Consulta</h3>
            </Row>

            <Form>
                <Form.Group className="mb-4 m-1 row">
                    <Form.Label className="col-md-1 text-md-end text-center my-auto">Cliente:</Form.Label>
                    <Dropdown id={"client"} className="col-md-5 text-md-start mb-md-0 mb-4 text-center">
                        <Dropdown.Toggle variant="secondary" className="clients-dropdown">{selectedClient}</Dropdown.Toggle>
                        <Dropdown.Menu className={"clients-dropdown-menu"} as={CustomMenu}>
                            {
                                clients.map(c =>
                                    <Dropdown.Item id={c.codigo} key={c.codigo} onClick={this.clientSelected}>
                                        {c.nome}
                                    </Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>

                    <Form.Label className="col text-md-end text-center my-auto">Exploração:</Form.Label>
                    <Dropdown className="col-md-auto text-md-start mb-md-0 mb-4 text-center">
                        <Dropdown.Toggle variant="secondary" className="clients-dropdown">{selectedExploration}</Dropdown.Toggle>
                        <Dropdown.Menu className="">
                            { explorations.map((currentValue, index) =>
                                    currentValue.trim() !== "" &&
                                        <Dropdown.Item key={index} onClick={e => this.setState({ selectedExploration: e.target.text })}>
                                            {currentValue}
                                        </Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Table size="sm" striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Artigo</th>
                            <th>
                                Descrição<br/>
                                <input
                                    type="text"
                                    className={"mx-auto w-auto form-control"}
                                    placeholder={"Filtrar por Descrição..."}
                                    value={this.state.productNameFilter}
                                    onChange={this.filterProducts}/>
                            </th>
                            <th>Lote</th>
                            <th>Validade</th>
                            <th>Stock Atual</th>
                            <th>Unidade</th>
                            <th>Quantidade Administrada</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            products.length > 0 &&
                                products.map((p, index) => {
                                    let stock = this.calculateStock(p.Stock, p.FactorConversao)
                                    let pvp = this.calculatePvp(p.PVP1, p.FactorConversao)
                                    return (<tr key={index}>
                                        <td>{p.Artigo}</td>
                                        <td>{p.Descricao}</td>
                                        <td>{p.Lote}</td>
                                        <td>{p.Validade}</td>
                                        <td>{stock}</td>
                                        <td>{p.Unidade}</td>
                                        <input type="number" className={"form-control tableInputs text-center w-100"}
                                               min={0} max={stock} placeholder={0} pvp={pvp}
                                               onChange={this.verifyInputValue} />
                                    </tr>) }
                                )
                        }
                    </tbody>
                </Table>

                <p id={"formWarning"} className={"text-danger mt-2"}/>

                <div className="row justify-content-center">
                    <Button id="cleanDataBtn" className="col-xl-2 col-md-3 col-sm-5 col-7 text-sm-center mb-3 mb-sm-0 me-sm-3"
                            onClick={this.cleanData}>
                        Limpar Dados
                    </Button>
                    <Button id="saveBtn" className="col-xl-2 col-md-3 col-sm-5 col-7 text-sm-center mb-3 mb-sm-0 ms-sm-3
                        green-bg"
                        onClick={this.showConfirmationModal}>
                        Registar
                    </Button>
                </div>

                {/* Model para confirmar o registo dos medicamentos */}
                <div id={"modal"} className="modal fade in" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Registo do Documento</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Tem a certeza que pretende proceder ao registo dos medicamentos administrados?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                        onClick={this.closeModal}>Cancelar</button>
                                <button type="button" className="btn btn-primary green-bg"
                                        onClick={this.documentRegistration}>Registar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </BlockUi>
    }

    /**
     * Método chamado quando um cliente é selecionado
     * Coloca as explorações associadas ao cliente selecionado na ComboBox correspondente
     */
    clientSelected = (e) => {
        let selectedClientName = e.target.text;
        let selectedClientId = e.target.getAttribute("id");

        let clients = this.state.clients;
        for (let i = 0; i < clients.length; i++) {
            let clientId = clients[i].codigo;
            if (selectedClientId === clientId) {
                this.setState({
                    selectedExploration: "Selecione a Exploração",
                    explorations: clients[i].exploracoes
                });
            }
        }

        this.setState({ selectedClient: [selectedClientName, selectedClientId] });
    }

    /**
     * Função que calcula o stock com base no fator de conversão, caso exista
     * @param stock valor em stock (na Unidade Base)
     * @param factor factor de conversão (converter para a Unidade de Venda)
     * @returns {number|*} stock atual (na Unidade de Venda)
     */
    calculateStock(stock, factor) {
        if (factor == null) return stock
        else {
            let val = stock / factor
            return val % 1 === 0.0 ? val : val.toFixed(2)
        }
    }

    /**
     * Função que calcula o preço unitário do produto tendo em conta a unidade de venda
     * @param pvp preço unitário (da Unidade Base)
     * @param factor factor de conversão (Unidade de Venda)
     * @returns {number|*} preço unitário na Unidade de Venda
     */
    calculatePvp(pvp, factor) {
        if (factor == null) return pvp
        else return pvp * factor
    }

    /**
     * Método chamado quando se altera o texto presente no filtro por nome de produto
     * Esconde as linhas cujos nomes dos produtos não coincidem com o texto inserido
     */
    filterProducts = (e) => {
        let filterText = e.target.value.toString().toLowerCase().trim();
        let rows = document.getElementsByTagName("tr");
        for (let i = 1; i < rows.length; i++) {
            let row = rows[i];
            let columns = row.getElementsByTagName("td");
            let colProductName = columns[1].textContent.toLowerCase();

            // Esconde-se a linha caso não coincida com o texto do filtro
            row.style.display = colProductName.includes(filterText) ? "" : "none";
        }

        this.setState({ productNameFilter: filterText });
    }

    /**
     * Verifica se a quantidade administrada não é superior ao stock atual
     * Caso seja, reinicia para 0 a quantidade administrada
     * @param e
     */
    verifyInputValue = (e) => {
        let inputValue = e.target.value.toString();
        let maxValue = parseFloat(e.target.getAttribute("max"));

        // Retira-se o sinal "-", caso exista
        inputValue = inputValue.replace("-", "");
        e.target.value = inputValue;

        // Caso o valor inserido seja maior que o stock atual, coloca-se a zero o valor inserido
        if (parseFloat(inputValue) > maxValue) {
            e.target.value = 0;
        }

        this.forceUpdate();
    }

    /**
     * Método chamado após o clique no botão "Limpar Dados"
     * Limpa o campo do cliente, exploração e coloca todas as quantidades administradas de cada produto a zero
     */
    cleanData = () => {
        this.setState({ selectedClient: ["Selecione o Cliente", null],
            selectedExploration: "Selecione a Exploração", explorations: [] })
        this.resetInputs();
    }

    /**
     * Reset de todas as quantidades administradas
     */
    resetInputs() {
        let inputs = document.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) inputs[i].value = 0;
    }

    /***
     * Método chamado aquando do clique no botão "Registar"
     * Exibe o Modal onde o utilizador confirma se pretende efetivamente registar o documento
     */
    showConfirmationModal = () => {
        let selectedClient = this.state.selectedClient[1];
        let selectedExploration = this.state.selectedExploration;
        let alert = document.getElementById("formWarning");
        alert.classList.remove("text-danger");
        alert.classList.add("text-danger");

        if (selectedClient === null) {
            this.showFeedback("Antes de proceder ao registo, selecione o cliente.", true);
        } else if (selectedExploration.includes("Selecione a Exploração")) {
            this.showFeedback("Antes de proceder ao registo, selecione a exploração.", true);
        } else if (!this.enteredAtLeastOneProductQuantity()) {
            this.showFeedback("Tem de inserir uma quantidade administrada em pelo menos 1 dos produtos.", true);
        }
        else {
            alert.style.visibility = "hidden";

            // Exibição do modal
            let modal = document.getElementById("modal");
            modal.style.display = "block";
            modal.classList.add("show");
        }
    }

    /**
     * Verifica se foi inserida pelo menos uma quantidade administrada de um produto
     * @returns {boolean} verdadeiro se foi inserida, falso caso contrário
     */
    enteredAtLeastOneProductQuantity() {
        let lines = document.getElementsByTagName("tr");
        for (let i = 1; i < lines.length; i++) {
            let inputElement = lines[i].getElementsByTagName("input")[0];
            let quantity = inputElement.value;
            if (quantity !== "" && quantity > 0) return true;
        }
        return false;
    }

    /***
     * Registo do documento no ERP Primavera
     */
    documentRegistration = async () => {
        // Fecha-se o modal e bloqueia-se-se a interface
        this.closeModal();

        // Obtenção dos produtos administrados, com o respetivo stock e quantidade
        let lines = document.getElementsByTagName("tr");
        let products = [];
        const warehouse = this.state.warehouse;
        for (let i = 1; i < lines.length; i++) {
            let inputElement = lines[i].getElementsByTagName("input")[0];
            let quantity = inputElement.value;
            if (quantity !== "" && quantity > 0) {
                let productInfo = lines[i].getElementsByTagName("td");
                let productCode = productInfo[0].innerText;
                let batch = productInfo[2].innerText;
                let pvp = inputElement.getAttribute("pvp");
                products.push({"Artigo": productCode, "Armazem": warehouse, "Localizacao": warehouse, "Lote": batch,
                    "Quantidade": quantity, "PrecUnit": parseFloat(pvp)});
            }
        }

        if (products.length === 0) {
            // Verificação se existe algum produto com alguma quantidade inserida
            alert("Não é possível efetuar o registo pois não inseriu nenhuma quantidade em nenhum dos produtos.");
        } else {
            this.toggleBlocking();

            const api = new ApiOperations();
            let clientId = this.state.selectedClient[1];
            let exploration = this.state.selectedExploration;

            let result = await api.createDocument(clientId, products, exploration);

            // Caso exista um problema na comunicação com a WebApi ou na criação do documento
            if (result === undefined || result === false) {
                this.showFeedback("Não foi possível criar o documento, verifique a sua ligação à Internet e " +
                    "tente outra vez", true);
                this.toggleBlocking();
                return;
            }

            await this.fetchData();

            // Limpa-se os inputs e informa-se o utilizador do sucesso da operação
            this.resetInputs();
            this.showFeedback("Documento registado com sucesso.");

            // Desbloqueia-se a interface
            this.toggleBlocking();
        }
    }

    /***
     * Clique no botão "Cancelar", do Modal
     * Fecha-se o Modal
     */
    closeModal = () => {
        let modal = document.getElementById("modal");
        modal.style.display = "none";
        modal.classList.remove("show");
    }

    /**
     * Exibição da mensagem de feedback (pode ser de erro ou de sucesso)
     * @param msg texto a ser exibido
     * @param isError indica se a mensagem é de erro
     */
    showFeedback(msg, isError = false) {
        let alert = document.getElementById("formWarning");
        if (isError) {
            alert.classList.add("text-danger");
            alert.classList.remove("text-success");
        } else {
            alert.classList.remove("text-danger");
            alert.classList.add("text-success");
        }
        alert.style.visibility = "visible";
        alert.innerText = msg;
    }


}

export default ConsultationRecord;