import React from 'react';
import './App.css';
import Login from './components/Login';
import Main from './components/Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
      <Router>
          <div className="App">
              <Switch>
                  <Route path="/" component={Login} exact />
                  <Route path="/Consulta" component={Main} />
                  <Route path="/Historico" component={Main} />
              </Switch>
          </div>
      </Router>
  );
}

export default App;
