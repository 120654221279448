import React from "react";
import {Dropdown, Row} from "react-bootstrap";
import {Link, Redirect} from "react-router-dom";

class HomepageHeader extends React.Component {

    state = { vetsName: "" }

    async componentDidMount() {
        const vetsName = sessionStorage.getItem("vetsName");
        this.setState({ vetsName: vetsName });
    }

    render() {
        const val = sessionStorage.getItem("login");

        if (val === "false") {
            return <Redirect to="/" />
        } else {
            const vetsName = this.state.vetsName;
            return <div>
                <Row className="mt-5 justify-content-center">
                    <div className="col-2"></div>
                    <h1 id="homepageTitle" className="col-md text-center">AACB Vet. - GSCloud</h1><br/>

                    <Dropdown className="col-md-2 text-md-end mb-md-0 mb-4 text-center">
                        <Dropdown.Toggle variant="secondary" className="clients-dropdown">Opções</Dropdown.Toggle>
                        <Dropdown.Menu className={"clients-dropdown-menu"} >
                            <Dropdown.Item className="options-menu-item btn-sm" as={Link} to="/Consulta">
                                Registar Consulta
                            </Dropdown.Item>
                            <Dropdown.Item className="options-menu-item btn-sm" as={Link} to="/Historico">
                                Histórico de Registos
                            </Dropdown.Item>
                            <Dropdown.Item id="logOutBtn" className="btn-sm" onClick={this.logOut}>
                                Terminar Sessão
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Row>

                <div className="mt-0 mt-md-0">
                    <p className="col fw-bold">{vetsName}</p>
                </div>
            </div>;
        }
    }

    /**
     * Clique no botão "Página Inicial"
     * Redireciona-se o utilizador para a homepage
     */
    /* handleHomepageClick = () => {
        window.location.href = "/Consulta";
    } */

    /**
     * Clique no botão "Histórico de Registos"
     * Redireciona-se o utilizador para a página de histórico de registos
     */
    /* handleHistoryClick = () => {
        window.location.href = "/Historico";
    } */

    /**
     * Clique no botão "Terminar Sessão"
     * Termina-se a sessão e redireciona-se o utilizador para o ecrã de login
     */
    logOut = () => {
        sessionStorage.removeItem("vetsName");
        sessionStorage.removeItem("warehouse");
        sessionStorage.setItem("login", "false");
        this.forceUpdate();
    }
}

export default HomepageHeader;