import React, { useState }  from 'react';
import {Form, Button, Col, Row, Container, Spinner} from "react-bootstrap";
import '../css/login.css';
import logo from '../assets/logo.png';
import { ApiOperations } from '../api/apiOperations';
import { Redirect } from "react-router-dom";

class Login extends React.Component {

    state = {
        username: null,
        password: null,
        warehouse: null,
        vetsName: null,
        isLoading: false
    }

    render() {
        const val = sessionStorage.getItem("login");
        const isLoading = this.state.isLoading;

        if (val === "true") {
            return <Redirect to={{
                pathname: "/Consulta"
            }} />
        }
        else {
            return <Container fluid id="loginLayout">
                    <img id="logo" src={logo} className="img-fluid"  alt="Logotipo da Associação de Agricultores do Campo Branco"/>
                    <Form className="login-box my-auto" onSubmit={this.handleSubmit}>
                        <h1 className="mb-3">Inicio de Sessão</h1>
                        <Form.Group className="mb-3 row justify-content-center">
                            <Col md={5} className="p-0">
                                <Form.Control className="login-input" type="text" placeholder="Utilizador:"
                                              onChange={(e) => this.setState({ username: e.target.value })}
                                              required />
                            </Col>
                        </Form.Group>

                        <Form.Group className="mb-3 row justify-content-center">
                            <Col md={5} className="p-0">
                                <Form.Control className="login-input" type="password" placeholder="Palavra-Passe:"
                                              onChange={(e) => this.setState({ password: e.target.value })}
                                              required />
                            </Col>
                        </Form.Group>
                        <Row className="justify-content-center">
                            <Button id="submitBtn" className="col-md-5" type="submit">
                                <Spinner className="p-1" as="span" variant="secondary" size="sm"
                                    role="status" aria-hidden="true" animation="border" hidden={!isLoading} />
                                Entrar
                            </Button>
                        </Row>
                        <p id={"errorLogin"} className={"text-danger mt-2"}></p>
                    </Form>
                </Container>
        }
    }

    /**
     * Verificação se as credenciais inseridas correspondem às credenciais de algum veterinário
     */
    handleSubmit = async (e) => {
        e.preventDefault();

        // Ativação do spinner do botão de "Entrar"
        this.setState({ isLoading: true });

        // Username e password inseridas e verificação das credenciais
        let username = this.state.username;
        let password = this.state.password;
        const api = new ApiOperations();
        let result = await api.checkLogin(username, password);

        if (result === undefined) {
            let errorLogin = document.getElementById("errorLogin");
            errorLogin.style.visibility = "visible";
            errorLogin.innerText = "Ocorreu um erro na comunicação com o servidor. " +
                "Por favor, verifique a sua ligação à Internet e tente novamente.";
        } else if (result.loginValido) {
            this.setState({ warehouse: result.armazem, vetsName: result.nomeVet });
            sessionStorage.setItem("login", "true");
            sessionStorage.setItem("vetsName", this.state.vetsName);
            sessionStorage.setItem("warehouse", this.state.warehouse);
            this.forceUpdate()
        } else {
            sessionStorage.setItem("login", "false");
            let errorLogin = document.getElementById("errorLogin");
            errorLogin.style.visibility = "visible";
            errorLogin.innerText = "Credenciais Inválidas";
        }

        // Desativação do spinner do botão de "Entrar"
        this.setState({ isLoading: false });
    }
}

export default Login;