export class ApiOperations {

    baseUrl = "https://campobranco.gscloud.pt:1443/WebApi/"; // "http://213.13.132.145:2018/WebApi/";
    // baseUrl = "http://172.16.2.2:2018/WebApi/";

    /***
     * Obtenção de um token de acesso para utilização da Web Api Primavera
     * @returns token de acesso à Api, ou null caso não seja possível obtê-lo
     */
    async getToken() {
        let headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("username", "admin");
        urlencoded.append("password", "admin1234");
        urlencoded.append("company", "AACB2018"); // TESTE1; TESTEWEB
        urlencoded.append("instance", "Default");
        urlencoded.append("grant_type", "password");
        urlencoded.append("line", "professional");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: urlencoded,
            redirect: 'follow'
        };

        let token;
        await fetch(`${this.baseUrl}token`, requestOptions)
            .then(response => response.text())
            .then(result => {
                result = JSON.parse(result);
                token = result.access_token;
            })
            .catch(() => token = undefined);

        return token;
    }

    /**
     * Verificação se o token é válido
     * @param token token
     * @returns {boolean} verdadeiro se for válido, falso caso contrário
     */
    checkIfTokenIsValid(token) {
        return token !== undefined;
    }

    /**
     * Verificação se as credenciais inseridas no login são válidas
     * @param username nome de utilizador inserido
     * @param password inserida
     * @returns informação se o login é válido, ou null caso não seja possível verificar
     */
    async checkLogin(username, password) {
        // Obtenção do token de acess
        let token = await this.getToken();
        if (!this.checkIfTokenIsValid(token)) return undefined;

        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        // Verificação se as credenciais são válidas
        let obj;
        await fetch(`${this.baseUrl}ClassExtended/VerificaVeterinarioeExiste/${username}/${password}`, requestOptions)
            .then(response => response.text())
            .then(result => obj = JSON.parse(result))
            .catch(() => obj = null);
        return obj;
    }

    /***
     * Obtenção dos clientes
     * @returns objeto com os diversos com os clientes, ou null caso não seja possível obtê-los
     */
    async getClients() {
        // Obtenção do token de acesso
        let token = await this.getToken();
        if (!this.checkIfTokenIsValid(token)) return undefined;

        // Obtenção dos clientes
        let headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };

        let obj = null;
        await fetch(`${this.baseUrl}ClassExtended/ObterClientesERespetivaExploracao`, requestOptions)
            .then(response => response.text())
            .then(result => {
                obj = JSON.parse(result);
            });
        return obj;
    }

    /***
     * Obtenção dos produtos presentes num determinado armazém
     * @param warehouse armazém em questão
     * @returns objeto com informação sobre os diversos produtos num determinado armazém
     */
    async getProductsInWarehouse(warehouse) {
        // Obtenção do token de acesso
        let token = await this.getToken();
        if (!this.checkIfTokenIsValid(token)) return undefined;

        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };

        // Obtenção dos produtos
        let obj = null;
        await fetch(`${this.baseUrl}ClassExtended/ObterArtigosNoArmazem/${warehouse}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                obj = JSON.parse(result);
            });

        return obj;
    }

    /***
     * Criação do documento (GC - Guia de Consumo) no ERP Primavera
     * @param clientId código do cliente
     * @param products lista de produtos e respetivas quantidades administradas
     * @param exploration designação da exploração
     * @returns {Promise<void>}
     */
    async createDocument(clientId, products, exploration) {
        // Obtenção do token de acesso
        let token = await this.getToken();
        if (!this.checkIfTokenIsValid(token)) return undefined;

        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        let serie = new Date().getFullYear();
        let raw = JSON.stringify({
            "Linhas": products,
            "Tipodoc": "GC",
            "Serie": serie,
            "Entidade": clientId,
            "TipoEntidade": "C"
        });

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        // Criação do documento
        let res;
        await fetch(`${this.baseUrl}Vendas/Docs/CreateDocument/`, requestOptions)
            .then(async (result) => {
                // Atualização da exploração no documento criado
                if (result) {
                    await fetch(`${this.baseUrl}ClassExtended/AtualizarExploracaoNoDocumento/${exploration}/${clientId}`,
                        requestOptions).then(result => {
                        res = result;
                    }).catch(error => {
                        res = false;
                    })
                }
            })
            .catch(error => {
                res = false;
            });

        return res;
    }

    /***
     * Obtenção dos documentos (GC - Guia de Consumo) registados no ERP Primavera, em um determinado armazém
     * @param warehouse código do armazém
     * @returns {Promise<void>} objeto com informação sobre os documentos (GC) com produtos que saíram de um
     * determinado armazém
     */
    async getDocumentByWarehouse(warehouse) {
        // Obtenção do token de acesso
        let token = await this.getToken();
        if (!this.checkIfTokenIsValid(token)) return undefined;

        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };

        // Obtenção dos documentos
        let obj = null;
        await fetch(`${this.baseUrl}ClassExtended/ObterDocumentosRegistadosEmArmazem/${warehouse}`, requestOptions)// ALTERAR POR: ${warehouse}
            .then(response => response.text())
            .then(result => {
                obj = JSON.parse(result);
            });

        return obj;
    }
}