import React, {useState} from "react";
import {Dropdown, Form, Row, Table} from "react-bootstrap";
import {ApiOperations} from "../api/apiOperations";
import BlockUi from "react-block-ui";

class History extends React.Component {

    state = {
        documents: [],
        blocking: false,
        selectedClient: ["Selecione o Cliente", null], // Nome e Código do cliente
        clients: []
    }

    constructor(props) {
        super(props);
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    /**
     * Obtenção dos documentos registados no armazém do veterinário e dos clientes
     */
    async fetchData() {
        // Bloqueio da interface enquanto se carrega os dados
        this.toggleBlocking()

        const api = new ApiOperations();

        // Obtenção dos documentos
        const warehouse = sessionStorage.getItem("warehouse");
        let documents = await api.getDocumentByWarehouse(warehouse);
        documents = this.structureDocument(documents.documentos);

        // Obtenção dos clientes e ordenação pelo nome
        let clients = this.structureClients(documents);
        clients.sort((c1, c2) => c1.NomeCliente.localeCompare(c2.NomeCliente));

        // Atualização do state com a informação sobre os documentos (GC - guias de consumo) e clientes
        this.setState({ documents: documents, clients: clients });

        // Desbloqueio da interface depois dos dados carregados
        this.toggleBlocking()
    }

    /**
     * Método responsável por inverter a variável que determina o bloqueio da interface
     */
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    /**
     * Função responsável por estruturar os documentos numa lista
     * Essa lista possui apenas um ‘item’ por documento
     * Cada elemento da lista contém informação sobre esse documento (ID, data, lista de artigos, etc.)
     * @param documents array com informação dos documentos
     */
    structureDocument(documents) {
        let documentsObj = [];
        let products = [];
        let previousDoc = "";
        let nDocs = documents.length;
        documents.forEach((doc, index) => {
            let documentId = doc.Documento;

            if (previousDoc !== "" && documentId !== previousDoc.Documento) {
                // Caso o documento difira do anterior, adiciona-se o anterior à lista
                documentsObj.push({
                    Documento: previousDoc.Documento,
                    Cliente: previousDoc.Cliente,
                    NomeCliente: previousDoc.NomeCliente,
                    NIF: previousDoc.NIF,
                    Data: previousDoc.Data,
                    Hora: previousDoc.Hora,
                    Artigos: products
                });

                products = [];
            }

            // Estruturação da lista de artigos do documento
            products.push({
                Artigo: doc.Artigo,
                Descricao: doc.Descricao,
                Lote: doc.Lote,
                Quantidade: doc.Quantidade,
                Unidade: doc.Unidade
            });

            // Caso se trate do último documento, adiciona-se desde logo o mesmo à lista
            if (nDocs - 1 === index) {
                documentsObj.push({
                    Documento: documentId,
                    Cliente: previousDoc.Cliente,
                    NomeCliente: previousDoc.NomeCliente,
                    NIF: previousDoc.NIF,
                    Data: doc.Data,
                    Hora: doc.Hora,
                    Artigos: products
                });
            }

            previousDoc = doc;
        });

        return documentsObj;
    }

    /**
     * Função responsável por estruturar os clientes dos documentos registados numa lista.
     * Cada elemento da lista contém informação sobre um cliente (ID, nome e número de contribuinte).
     * @param documents array com informação dos documentos.
     * @return array com a informação sobre os clientes.
     */
    structureClients(documents) {
        let clients = [];
        let clientsIds = [];

        documents.forEach(doc => {
            // Caso a lista ainda não inclua o cliente, adiciona-se
            if (!clientsIds.includes(doc.Cliente)) {
                clients.push({
                    Cliente: doc.Cliente,
                    NomeCliente: doc.NomeCliente,
                    NIF: doc.NIF
                });

                clientsIds.push(doc.Cliente);
            }
        });

        return clients;
    }

    render() {
        let documents = this.state.documents;

        // Variáveis para  o filtro do cliente
        let clients = this.state.clients;
        let selectedClient = this.state.selectedClient[0];

        // Configuração do filtro/pesquisa no cliente
        const HistoryCustomMenu = React.forwardRef(
            ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
                const [value, setValue] = useState('');

                return (
                    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                        <Form.Control autoFocus className="mx-3 my-2 w-75" placeholder="Filtrar por Nome ou N.º de Contribuinte..."
                                      onChange={(e) => setValue(e.target.value)} value={value}/>
                        <ul className="list-unstyled">
                            {React.Children.toArray(children).filter(
                                (child) => {
                                    /* Critérios do filtro de pesquisa:
                                         - Pelo nome do cliente
                                         - Pelo NIF do cliente
                                     */
                                    let text = value.toLowerCase().trim()
                                    let clientName = child.props.children;
                                    let clientObj = clients.find(c => c.NomeCliente === clientName);
                                    return clientObj == null ||
                                        !text ||
                                        clientName.toLowerCase().includes(text) ||
                                        clientObj.NIF.includes(text)
                                }
                            )} </ul>
                    </div>
                );
            },
        );

        return <BlockUi tag="div" blocking={this.state.blocking}>
            <hr />

            <Row className="justify-content-center">
                <h3 className={"col-auto"}>Histórico de Registos</h3>
            </Row>

            <div className={"row mb-2 m-1"}>
                <Form.Label className="col-md-1 text-md-end text-center my-auto" onClick={this.clientSelected}>Cliente:</Form.Label>
                <Dropdown id={"historyClient"} className="col-md-5 text-md-start mb-md-0 mb-4 text-center">
                    <Dropdown.Toggle variant="secondary" className={"clients-dropdown"}>{selectedClient}</Dropdown.Toggle>
                    <Dropdown.Menu className={"clients-dropdown-menu"} as={HistoryCustomMenu}>
                        <Dropdown.Item key={"blank"} onClick={this.clientSelected}>Selecione o Cliente</Dropdown.Item>
                        {
                            clients.map(c =>
                                <Dropdown.Item id={c.Cliente} key={c.Cliente} onClick={this.clientSelected}>
                                    {c.NomeCliente}
                                </Dropdown.Item>)
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <Table size="sm" striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Documento</th>
                        <th>Cliente</th>
                        <th>Data</th>
                        <th>Hora</th>
                        <th>Número de Artigos</th>
                        <th>Artigos</th>
                    </tr>
                </thead>
                <tbody id={"body-main-table"}>
                {
                    documents.length > 0 &&
                    documents.map((d, index) => {
                        return (
                            <React.Fragment key={"fragment_" + index}>
                                <tr id={"documento_" + index} key={index}>
                                    <td className={"align-middle"}>{d.Documento}</td>
                                    <td className={"align-middle"}>{d.NomeCliente}</td>
                                    <td className={"align-middle"}>{d.Data}</td>
                                    <td className={"align-middle"}>{d.Hora}</td>
                                    <td className={"align-middle"}>{d.Artigos.length}</td>
                                    <td id={"button_" + index} className={"align-middle"} onClick={this.handleDocumentDetails}>
                                        <svg id={"svg_" + index} xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                        </svg>
                                    </td>
                                </tr>
                                <tr id={"artigos_" + index} key={"artigos_" + index} hidden>
                                    <td colSpan={6}>
                                        <Table striped bordered responsive>
                                            <thead>
                                                <tr>
                                                    <th>Artigo</th>
                                                    <th>Descrição</th>
                                                    <th>Lote</th>
                                                    <th>Unidade</th>
                                                    <th>Quantidade</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { d.Artigos.map((artigo, i) => {
                                                    return <tr key={`artigos_${index}_linha_${i}_${artigo.Artigo}`}>
                                                        <td>{artigo.Artigo}</td>
                                                        <td>{artigo.Descricao}</td>
                                                        <td>{artigo.Lote}</td>
                                                        <td>{artigo.Unidade}</td>
                                                        <td>{artigo.Quantidade}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </React.Fragment>
                        )}
                    )
                }
                </tbody>
            </Table>
        </BlockUi>
    }

    /**
     * Método que responde ao clique para expandir/esconder detalhes sobre um determinado documento.
     * Caso os detalhes de um determinado documento não estejam a ser exibidos aquando do clique, é exibida uma
     * tabela com informação dos artigos (código, lote, quantidade, etc.) presentes nesse documento.
     * No caso dos detalhes de um determinado documento estarem vísiveis na página aquando do clíque, a tabela com a
     * informação sobre os artigos do documento é escondida da página.
     */
    handleDocumentDetails = (e) => {
        let tdId = e.target.id;
        let index = tdId.split("_")[1];
        let td = document.getElementById("button_" + index);
        let svg = td.children[0];

        // Linha dos artigos a serem exibidos. Linha seguinte à do documento onde se clicou para "Ver Mais/Menos"
        let productsRow = document.getElementById("artigos_" + index);
        productsRow.hidden = !productsRow.hidden;

        // Substituição do botão (ícone) de ver/esconder os artigos
        if (productsRow.hidden === true) {
            svg.innerHTML = "<path d=\"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z\"/> " +
                "<path d=\"M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z\"/>";
        } else {
            svg.innerHTML = "<path d=\"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z\"/> " +
                "<path d=\"M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z\"/>";
        }
    }

    /**
     * Método chamado quando um cliente é selecionado.
     * Filtra a informação presente na tabela, exibindo os documentos associados ao cliente selecionado.
     */
    clientSelected = (e) => {
        // Dados do cliente selecionado no filtro
        let selectedClientName = e.target.text;
        let selectedClientId = e.target.getAttribute("id");

        let tbody = document.getElementById("body-main-table");
        let rows = tbody.children;
        for (let i = 0; i < rows.length; i += 2) {
            let tds = rows[i].children;
            rows[i].hidden = (selectedClientName !== "Selecione o Cliente" && tds[1].innerHTML !== selectedClientName);

            // Ao filtrar, escondem-se todas as tabelas com os detalhes de cada documento (artigos)
            rows[i + 1].hidden = true;
            let svg = tds[5].children[0];
            svg.innerHTML = "<path d=\"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z\"/> " +
                "<path d=\"M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z\"/>";
        }

        this.setState({ selectedClient: [selectedClientName, selectedClientId] });
    }
}

export default History;