import React from "react";
import {Container} from "react-bootstrap";
import HomepageHeader from '../components/HomepageHeader';
import ConsultationRecord from './ConsultationRecord';
import '../css/homepage.css'
import { Redirect } from "react-router-dom";
import History from "./History";

class Main extends React.Component {

    render() {
        const val = sessionStorage.getItem("login");
        if (val === "true") {
            // Decisão do conteúdo a exibir
            const pathname = window.location.pathname;
            let component;
            if (pathname.includes("Consulta")) {
                const warehouse = sessionStorage.getItem("warehouse");
                component = <ConsultationRecord warehouse={warehouse} />
            } else {
                component = <History />
            }

            return <Container className="mr-0 ml-0">
                <HomepageHeader />
                { component }
            </Container>;
        } else {
            return <Redirect to="/" />
        }
    }
}

export default Main;